<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label must_input">名称</div>
            <Input class="width_300" type="text" v-model.trim="formData.title" :maxlength="15" show-word-limit></Input>
        </div>
        <div class="form_item">
            <div class="form_label must_input">所在区域</div>
            <city v-model="address_code"></city>
        </div>
        <div class="form_item">
            <div class="form_label must_input">详细地址</div>
            <Input type="textarea" :row="5" class="width_300" v-model.trim="address_note" :maxlength="50" show-word-limit />
        </div>
        <div class="form_item">
            <div class="form_label must_input">优惠金额</div>
            <Input class="width_300" v-model.trim="formData.sale_price" :maxlength="5" />
        </div>
        <div class="form_item">
            <div class="form_label must_input">优惠码</div>
            <Input class="width_300" v-model.trim="formData.couponCode" :maxlength="20" show-word-limit />
        </div>
        <div class="form_item">
            <div class="form_label must_input">有效时间</div>
            <myDatetimerange v-model="showTimeArr"></myDatetimerange>
        </div>

        <div class="form_item">
            <div class="form_label must_input">状态</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">开启</Radio>
                <Radio label="0">关闭</Radio>
            </RadioGroup>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqDiscountCodeSave } from '@/lib/request/auth2';
import city from '@/components/city';
import myDatetimerange from '@/components/myDatetimerange';
import { getParamType, isNumberGt0, type_date } from '@/lib/util';
import { getCityTitles } from '@/lib/city.data';

export default {
    name: 'discountCodeAdd',
    components: {
        city,
        myDatetimerange,
    },
    data() {
        return {
            title: null,
            maxLength: 5,
            formData: {
                id: null,
                title: null,
                couponCode: null,
                sale_price: null,
                showTime: null,
                status: '0',
            },
            address_code: [],
            address_note: null,
            isEdit: false,
            showTimeArr: {
                start: '',
                end: '',
            },
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.title = this.isEdit ? '编辑优惠码' : '新增优惠码';
        if (this.isEdit) {
            let [start, end] = (this.formData.showTime || ',').split(',');
            this.showTimeArr.start = new Date(start);
            this.showTimeArr.end = new Date(end);
        }
    },
    watch: {
        //
    },
    methods: {
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, sale_price } = params || {};
            let vs = [
                { value: title, tip: '请输入名称' },
                { value: this.address_note, tip: '请输入详细地址' },
                { value: sale_price, tip: '请输入优惠金额' },
            ];
            if (this.address_code.length <= 0) {
                this.fadeWarning('请选择所在区域');
                this.hideAjaxLoading();
                return;
            }
            if (!isNumberGt0(sale_price)) {
                this.fadeWarning('请输入有效的优惠金额');
                this.hideAjaxLoading();
                return;
            }

            hm.validateForm(vs)
                .then(() => {
                    let address_code = this.address_code;
                    [params.sheng, params.shi, params.qu] = address_code || ['', '', ''];
                    params.address = getCityTitles(address_code).join(',') + ';' + this.address_note;

                    if (!!this.showTimeArr.start && !!this.showTimeArr.end) {
                        let { start, end } = this.showTimeArr || {};
                        if (getParamType(start) === type_date) {
                            start = start.Format('yyyy-MM-dd HH:mm:ss');
                            end = end.Format('yyyy-MM-dd HH:mm:ss');
                        }
                        params.showTime = `${start},${end}`;
                    } else {
                        this.fadeWarning('请选择有效时间');
                        this.hideAjaxLoading();
                        return;
                    }

                    // if (1 == 1) {
                    //     console.log(JSON.stringify(params, null, '\t'));
                    //     return;
                    // }

                    reqDiscountCodeSave(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
