<template>
	<DatePicker :transfer="true" :value="[value.start, value.end]" :editable="false" :style="{ width: width + 'px' }" type="datetimerange" placeholder="请选择" @on-change="onChangeDate"></DatePicker>
</template>
<script>
const logTitle = 'myDatetimerange';
export default {
	name: 'myDatetimerange',
	props: {
		value: {
			type: Object,
			required: false,
			default: function () {
				return { start: '', end: '' };
			},
		},		
		width: {
			type: Number,
			required: false,
			default: 320,
		},
	},
	data() {
		return {
			date: null,
		};
	},
	methods: {
		onChangeDate(v1) {            
			let [start, end] = v1;
			console.log(logTitle, 'date：' + v1);
			this.$emit('input', { start, end });
		},
	},
};
</script>
<style lang="less" scoped>
.input {
	width: 150px;
}
</style>
